/* SteinListe.css */

/* Steine-Listen-Abschnitt */
.stein-liste {
  margin-top: 2rem;
}

.stein-liste h2 {
  margin-bottom: 1rem;
  color: #4a148c;
}

.stein-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.stein-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stein-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.stein-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.stein-card h3 {
  padding: 1rem;
  background-color: #4a148c;
  color: #fff;
  font-size: 1.25rem;
}

.stein-card p {
  padding: 0 1rem 1rem 1rem;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem 1rem 1rem;
}

.button-group button {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bearbeiten-button {
  background-color: #1976d2;
  color: #fff;
}

.bearbeiten-button:hover {
  background-color: #1565c0;
}

.loeschen-button {
  background-color: #e53935;
  color: #fff;
}

.loeschen-button:hover {
  background-color: #c62828;
}

.btn-add {
  display: inline-block;
  background-color: #4a148c;
  color: #fff;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.btn-add:hover {
  background-color: #6a1b9a;
}

/* Optional: Margin-Top hinzufügen, um Abstand zum vorherigen Element zu schaffen */
.btn-add {
  margin-top: 1rem;
}

.section-divider {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 2rem 0;
}

/* Details in der Steinkarte */
.stein-details {
  padding: 0.75rem; /* Weniger Innenabstand */
}

.stein-name {
  font-size: 1.2rem;
  color: #4a148c;
  margin-bottom: 0.5rem; /* Weniger Abstand zum nächsten Element */
}

.stein-property {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  color: #333;
}

.property-label {
  font-weight: bold;
  color: #6a1b9a;
}

.property-value {
  color: #555;
}


/* Responsive Design */
@media (max-width: 768px) {
  .stein-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .stein-card {
    margin: 0 auto;
    width: 90%;
  }
}
